import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private apiURL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  sendEmailVerificationInstructions() {
    return this.http.post(
      this.apiURL + '/api/user/send/verify/email/instructions',
      {},
    );
  }

  sendPasswordInstructions() {
    return this.http.post(
      this.apiURL + '/api/user/reset/password/instructions',
      {},
    );
  }

  savePreferences(id: number, data: any) {
    return this.http.patch(this.apiURL + '/api/preferences/' + id, data);
  }

  getPreferences(id: number) {
    return this.http.get(this.apiURL + '/api/preferences/' + id);
  }
}
